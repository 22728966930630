import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Component2 from '@reach/component-component';
import InView from 'react-intersection-observer';
import moment from 'moment-timezone';
import Select from 'react-select';
import qs from 'query-string';

import ContentWrapper from '../../components/ContentWrapper';
import { gql, useQuery } from '../../components/ApolloClient';
import { TdLink } from '../../components';
import { LoadingSpinnerCentered } from '../../components/LoadingSpinner';

const basePath = 'ai-ecommerce-jobs';

const AiEcommerceVideoJobsList = ({
  location,
  history,
  session,
  cursors,
  setCursors,
}: {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  cursors: string[];
  setCursors: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const ecommerceJobTypesFilter = useQuery<{
    ecommerceJobListFiltersAsAdmin: {
      value: string;
      label: string;
    }[];
  }>(
    gql`
      query ecommerceJobListFiltersAsAdminQuery {
        ecommerceJobListFiltersAsAdmin {
          value
          label
        }
      }
    `
  );

  const ecommerceJobTypeOptions =
    ecommerceJobTypesFilter.data?.ecommerceJobListFiltersAsAdmin;

  const selectedValue =
    qs.parse(location.search).type ?? 'aiEcommerceVideoProduct';

  const selectedOption = ecommerceJobTypeOptions?.find(
    (o) => o.value === selectedValue
  );

  if (ecommerceJobTypeOptions == null) {
    return <LoadingSpinnerCentered />;
  }

  return (
    <div
      className="table-responsive"
      style={{
        position: 'relative',
        padding: '16px',
      }}
    >
      <div style={{ width: '100px' }}>
        <Select
          value={selectedOption}
          options={ecommerceJobTypeOptions}
          placeholder="Job"
          type
          defaultValue={ecommerceJobTypeOptions.find(
            (option) => option.value === 'aiEcommerceVideoProduct'
          )}
          onChange={(selectedOption) => {
            const selected = selectedOption?.value;

            history.push({
              ...location,
              search: qs.stringify({
                ...qs.parse(location.search),
                type: selected,
              }),
            });
          }}
        />
      </div>

      <div style={{ margin: 8, display: 'flex', flexWrap: 'wrap' }}>
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="text-muted text-truncate">ID</th>
              <th className="text-muted text-truncate">Name</th>
              <th className="text-muted text-truncate">Status</th>
              <th className="text-muted text-truncate">Type</th>
              <th className="text-muted text-truncate">Created at</th>
            </tr>
          </thead>

          <tbody>
            {[null, ...cursors].map((cursor, index) => (
              <DataRowsChunk
                location={location}
                history={history}
                key={cursor ?? '0'}
                cursor={cursor ?? '0'}
                session={session}
                onMore={
                  index === cursors.length
                    ? ({ nextCursor }) =>
                        setCursors((state) =>
                          state.includes(nextCursor)
                            ? state
                            : [...state, nextCursor]
                        )
                    : undefined
                }
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function DataRowsChunk({
  cursor,
  session,
  location,
  history,
  onMore,
}: {
  cursor?: string;
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  onMore?: ({ nextCursor }: { nextCursor: string }) => void;
}) {
  const ecommerceVideoList = useQuery<{
    ecommerceJobListAsAdmin: {
      cursor: string;
      edges: {
        id: string;
        name: string;
        status?: string;
        createdAt: string;
        package: {
          id: string;
          price: number;
          displayName: string;
          type: string;
        };
        aspectRatio: string;
        aParsnipMediaId: string;
        durationInSeconds: number;
        additionalNotes: string;
      }[];
    };
  }>(
    gql`
      query ecommerceJobListAsAdminQuery(
        $cursor: String
        $first: Int
        $search: String
        $jobTypeFilter: [String!]
      ) {
        ecommerceJobListAsAdmin(
          cursor: $cursor
          first: $first
          search: $search
          jobTypeFilter: $jobTypeFilter
        ) {
          cursor
          edges {
            id
            name
            status
            createdAt
            package {
              id
              price
              displayName
              type
            }
            aspectRatio
            durationInSeconds
            additionalNotes
          }
        }
      }
    `,
    {
      variables: {
        cursor,
        first: cursor == null ? 20 : 40,
        jobTypeFilter:
          qs.parse(location.search).type ?? 'aiEcommerceVideoProduct',
      },
      fetchPolicy: 'network-only',
    }
  );

  //TODO: fix status for non video jobs

  const videoList = ecommerceVideoList.data?.ecommerceJobListAsAdmin.edges;

  const nextCursor = ecommerceVideoList.data?.ecommerceJobListAsAdmin?.cursor;

  return (
    <>
      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}

      {videoList == null && ecommerceVideoList.loading
        ? Array.from(Array(4)).map((i, index) => (
            <tr key={'loading-' + index}>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
              <td>...</td>
            </tr>
          ))
        : videoList?.map((video, index) => (
            <tr key={video.id}>
              <TdLink
                title={video.id}
                to={`${basePath}/${video.id}`}
                className="text-truncate"
              >
                {video.id}
              </TdLink>

              <TdLink
                title={video.id}
                to={`${basePath}/${video.id}`}
                className="text-truncate"
              >
                {video.name}
              </TdLink>

              <TdLink
                title={video.id}
                to={`${basePath}/${video.id}`}
                className="text-truncate"
              >
                {video?.package.type === 'aiEcommerceVideoProduct' ? (
                  <span
                    className={`badge text-uppercase text-white ${
                      video.status === 'shipped'
                        ? 'badge-success'
                        : 'badge-warning'
                    }`}
                  >
                    {video.status ?? 'in progress'}
                  </span>
                ) : (
                  '-'
                )}
              </TdLink>

              <TdLink
                title={video.id}
                to={`${basePath}/${video.id}`}
                className="text-truncate"
              >
                {video.package.type.includes('aiEcommerceVideoProduct')
                  ? 'Video'
                  : 'Image'}
              </TdLink>

              <TdLink
                title={video.createdAt}
                to={`${basePath}/${video.id}`}
                className="text-truncate"
              >
                {moment(video.createdAt)
                  .tz(moment.tz.guess())
                  .format('YYYY-MM-DD HH:mm z')}
              </TdLink>
            </tr>
          ))}

      {onMore == null || nextCursor == null ? null : (
        <InView>
          {({ inView, ref }) => (
            <tr ref={ref}>
              {inView && <Component2 didMount={() => onMore({ nextCursor })} />}
            </tr>
          )}
        </InView>
      )}
    </>
  );
}

export const AiEcommerceVideoListRoute = ({
  location,
  session,
  history,
}: {
  location: RouteComponentProps['location'];
  session: {
    token: string;
    uid: string;
    user: {
      IAM: string[];
    };
  };
  history: RouteComponentProps['history'];
}) => {
  const [cursors, setCursors] = React.useState<string[]>([]);

  return (
    <>
      <h2>AI Ecommerce jobs</h2>
      <ContentWrapper>
        <div
          className="table-responsive"
          style={{
            position: 'relative',
          }}
        >
          <AiEcommerceVideoJobsList
            cursors={cursors}
            setCursors={setCursors}
            location={location}
            history={history}
            session={session}
          />
        </div>
      </ContentWrapper>
    </>
  );
};
