import React, { useEffect } from 'react';
import Select from 'react-select';
import { RouteComponentProps } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import upperCase from 'lodash/upperCase';
import isEqual from 'lodash/isEqual';
import Dropzone from 'react-dropzone';
import axios from 'axios';

import { apiFetch, formatCredits } from '../utils';
import { gql, useMutation, useQuery, useLazyQuery } from './ApolloClient';
import { Modal } from './Modal';
import { usePromise } from './usePromise';
import { locationForModal } from './ModalRoute';
import { USER_TYPE } from '../constants';

import pdfIcon from './img/pdf-icon.svg';

export const serviceType = {
  photography: 'photography',
  videography: 'videography',
};

type EnterpriseGuidelineProps = {
  partnerId: string;
  session: { token: string };
  onDismiss: () => void;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
  onContinue: () => void;
  modalParams: {
    modalName: string;
    guidelineProductIds: string[];
    modalType: 'create' | 'edit' | 'deactivate';
    guidelineId?: string;
    shootType: string;
    isReferral: boolean;
    guidelineName: string;
    userType:
      | 'artist'
      | 'end_customer'
      | 'editor'
      | 'referral_customer'
      | 'editor_vendor';
    sourceFile?: string;
    videoId?: string;
    pdfUrl: string;
    pdfFileName?: string;
    serviceType: string;
    addonIds?: string[];
  };
};

type Package = {
  uid: string;
  duration: number;
  price: number;
  type?: string;
  shootType: {
    category: string;
  };
};

type Addon = {
  uid: string;
  displayName?: number;
  price?: number;
};

export const EnterpriseGuidelineModal = ({
  partnerId,
  session,
  location,
  history,
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseGuidelineProps) => {
  const [uploadProgress, setUploadProgress] = React.useState<
    number | undefined
  >(undefined);

  const promiserDropZone = usePromise();
  const [getGuidelineByIdAsAdmin, getGuidelineByIdAsAdminQuery] = useLazyQuery<
    {
      guideline: {
        uid: string;
        name: string;
        userType: string;
        videoId?: string;
        fileUrl?: string;
        sourceFileUrl?: string;
        isReferral?: boolean;
        shootType?: {
          category?: string;
        };
        packages?: {
          uid: string;
          serviceType: string;
        }[];
        addons?: {
          edges: {
            uid: string;
          }[];
        };
      };
    },
    { guidelineId?: string }
  >(
    gql`
      query GetGuidelineByIdAsAdmin($guidelineId: ID!) {
        guideline: partnerGuidelineByIdAsAdmin(guidelineId: $guidelineId) {
          uid
          name
          userType
          videoId
          fileUrl
          sourceFileUrl
          isReferral

          shootType {
            category
          }

          packages {
            uid
            serviceType
          }

          addons {
            edges {
              uid
            }
          }
        }
      }
    `,
    {
      variables: { guidelineId: modalParams.guidelineId },
      onCompleted: (data) => {
        const pdfFileName = data.guideline.fileUrl
          ?.replace(/\b(https:\/\/media-asset\.snappr\.com\/)/, '')
          .replace(
            /(-[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})\b/,
            ''
          );

        history.replace(
          locationForModal({
            location,
            modal: {
              ...modalParams,
              guidelineName: data.guideline.name,
              shootType: data.guideline.shootType?.category,
              isReferral: data.guideline.isReferral,
              guidelineProductIds:
                data.guideline.packages?.map(({ uid }) => uid) ?? undefined,
              userType: USER_TYPE[data.guideline.userType].value,
              sourceFile: data.guideline.sourceFileUrl,
              videoId: data.guideline.videoId,
              pdfUrl: data.guideline.fileUrl,
              pdfFileName: pdfFileName,
              serviceType:
                (data.guideline.packages?.filter(
                  (p) => p.serviceType === serviceType.videography
                )?.length ?? []) === 0
                  ? serviceType.photography
                  : serviceType.videography,
              addonIds: data.guideline.addons?.edges.map((addon) => addon.uid),
            },
          })
        );
      },
    }
  );

  const shootTypesQuery = useQuery<{
    getPublicShootTypes: {
      displayName: string;
      category: string;
    }[];
  }>(gql`
    query GetPublicShootTypesQuery {
      getPublicShootTypes {
        category
        displayName
      }
    }
  `);

  const productsByShootTypeQuery = useQuery<{
    getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?: {
      edges: Package[];
    };
    getAddonsForGuidelineCreation: {
      edges?: Addon[];
    };
  }>(
    gql`
      query GetProductsByShootType(
        $partnerId: ID!
        $shootType: String!
        $serviceType: String!
      ) {
        getPublicPackagesByShootTypeForGuidelineCreationAsAdmin(
          partnerId: $partnerId
          shootType: $shootType
          serviceType: $serviceType
        ) {
          edges {
            uid
            duration
            price
            type
            shootType {
              category
            }
          }
        }

        getAddonsForGuidelineCreation(
          partnerId: $partnerId
          shootType: $shootType
          serviceType: $serviceType
        ) {
          edges {
            uid
            displayName
            price
          }
        }
      }
    `,
    {
      variables: {
        shootType: modalParams.shootType,
        serviceType: modalParams.serviceType,
        partnerId,
      },
      skip: modalParams.shootType == null || modalParams.serviceType == null,
    }
  );

  const [
    commitCreatePartnerGuidelineAsAdmin,
    commitCreatePartnerGuidelineAsAdminMutation,
  ] = useMutation<
    { guidelines?: { uid: string }[] },
    {
      partnerId: string;
      isReferral: boolean;
      name: string;
      shootType: string;
      productIds: string[];
      userType: string;
      fileUrl?: string;
      sourceFileUrl?: string;
      videoId?: string;
      serviceType: string;
      addonIds?: string[];
    }
  >(
    gql`
      mutation NewPartnerGuidelineCreateAsAdmin(
        $partnerId: ID!
        $isReferral: Boolean!
        $name: String!
        $shootType: String!
        $productIds: [ID!]!
        $userType: PartnerGuidelineUserTypeAsAdmin!
        $fileUrl: String
        $sourceFileUrl: String
        $videoId: String
        $serviceType: String!
        $addonIds: [ID!]
      ) {
        partnerGuidelineCreateAsAdmin(
          input: {
            partnerId: $partnerId
            isReferral: $isReferral
            name: $name
            shootType: $shootType
            productIds: $productIds
            userType: $userType
            fileUrl: $fileUrl
            sourceFileUrl: $sourceFileUrl
            videoId: $videoId
            serviceType: $serviceType
            addonIds: $addonIds
          }
        ) {
          guidelines {
            uid
          }
        }
      }
    `
  );

  const [
    commitPartnerGuidelineUpdateAsAdmin,
    commitPartnerGuidelineUpdateAsAdminMutation,
  ] = useMutation(
    gql`
      mutation PartnerGuidelineUpdateAsAdmin(
        $guidelineId: ID!
        $partnerId: ID!
        $isReferral: Boolean
        $name: String
        $shootType: String
        $productIds: [ID!]
        $userType: PartnerGuidelineUserTypeAsAdmin
        $fileUrl: String
        $sourceFileUrl: String
        $videoId: String
        $serviceType: String
        $addonIds: [ID!]
      ) {
        partnerGuidelineUpdateAsAdmin(
          input: {
            guidelineId: $guidelineId
            partnerId: $partnerId
            isReferral: $isReferral
            name: $name
            shootType: $shootType
            productIds: $productIds
            userType: $userType
            fileUrl: $fileUrl
            sourceFileUrl: $sourceFileUrl
            videoId: $videoId
            serviceType: $serviceType
            addonIds: $addonIds
          }
        ) {
          guideline {
            uid
          }
        }
      }
    `
  );

  const ALL_PACKAGES_OPTION = { value: 'ALL_PACKAGES', label: 'All packages' };

  const packages =
    productsByShootTypeQuery.data
      ?.getPublicPackagesByShootTypeForGuidelineCreationAsAdmin?.edges;

  const packageOptions = (packages ?? []).map((o) => ({
    label: `${o.duration / 60} ${o.duration / 60 === 1 ? 'Hour' : 'Hours'}`,
    value: o.uid,
  }));

  const addons = (
    productsByShootTypeQuery.data?.getAddonsForGuidelineCreation.edges ?? []
  ).map((addon) => ({
    value: addon.uid,
    label: `${addon.displayName} (${formatCredits(addon.price)} credits)`,
  }));

  const serviceTypeSelectOptions = [
    { label: 'Photography', value: 'photography' },
    { label: 'Videography', value: 'videography' },
  ];

  function onSubmit(event) {
    event.preventDefault();

    if (createGuidelineNotValid) {
      return;
    }

    if (modalParams.modalType === 'edit' && modalParams.guidelineId != null) {
      const originalData = getGuidelineByIdAsAdminQuery.data?.guideline;

      const isVideoServiceTypeFromOriginalData =
        (originalData?.packages?.filter(
          (p) => p.serviceType === serviceType.videography
        )?.length ?? []) !== 0;

      const originalServiceTypeSelected =
        isVideoServiceTypeFromOriginalData === false
          ? serviceType.photography
          : serviceType.videography;

      const originalAddons = originalData?.addons?.edges.map((a) => a.uid);

      commitPartnerGuidelineUpdateAsAdmin({
        variables: {
          guidelineId: modalParams.guidelineId,
          partnerId: partnerId,
          ...(isVideoServiceTypeFromOriginalData === false
            ? modalParams.isReferral !== originalData?.isReferral
              ? { isReferral: modalParams.isReferral }
              : {}
            : { isReferral: false }),
          ...(modalParams.guidelineName !== originalData?.name
            ? { name: modalParams.guidelineName }
            : {}),
          ...(modalParams.shootType !== originalData?.shootType?.category
            ? { shootType: modalParams.shootType }
            : {}),
          ...(isEqual(
            modalParams.guidelineProductIds,
            originalData?.packages?.map(({ uid }) => uid) ?? []
          ) === false
            ? {
                productIds: modalParams.guidelineProductIds.includes(
                  ALL_PACKAGES_OPTION.value
                )
                  ? packageOptions.map((o) => o.value)
                  : modalParams.guidelineProductIds,
              }
            : {}),
          ...(modalParams.userType !== upperCase(originalData?.userType)
            ? { userType: modalParams.userType }
            : {}),
          ...(modalParams.pdfUrl !== originalData?.fileUrl
            ? { fileUrl: modalParams.pdfUrl }
            : {}),
          ...(modalParams.sourceFile !== originalData?.sourceFileUrl
            ? { sourceFileUrl: modalParams.sourceFile }
            : {}),
          ...(modalParams.videoId !== originalData?.videoId
            ? { videoId: modalParams.videoId }
            : {}),
          ...(modalParams.serviceType !== originalServiceTypeSelected
            ? { serviceType: modalParams.serviceType }
            : {}),
          ...(isEqual(sortBy(modalParams.addonIds), sortBy(originalAddons))
            ? { addonIds: modalParams.addonIds ?? [] }
            : {}),
        },
      }).then(() => onContinue());
    } else {
      commitCreatePartnerGuidelineAsAdmin({
        variables: {
          partnerId: partnerId,
          isReferral: modalParams.isReferral ?? false,
          name: modalParams.guidelineName,
          shootType: modalParams.shootType,
          productIds: modalParams.guidelineProductIds.includes(
            ALL_PACKAGES_OPTION.value
          )
            ? packageOptions.map((o) => o.value)
            : modalParams.guidelineProductIds,
          userType: modalParams.userType,
          fileUrl: modalParams.pdfUrl,
          sourceFileUrl: modalParams.sourceFile,
          videoId: modalParams.videoId,
          serviceType: modalParams.serviceType,
          addonIds: modalParams.addonIds,
        },
      }).then(() => onContinue());
    }
  }

  useEffect(() => {
    if (modalParams.guidelineId != null) {
      getGuidelineByIdAsAdmin();
    }
  }, [modalParams.guidelineId, getGuidelineByIdAsAdmin]);

  const shootTypes = shootTypesQuery.data?.getPublicShootTypes;

  const selectedProductIdsOptions =
    modalParams.shootType === 'realestate' ||
    modalParams.userType === USER_TYPE.editor.value ||
    modalParams.userType === USER_TYPE.referral_customer.value ||
    modalParams.userType === USER_TYPE.editor_vendor.value
      ? [ALL_PACKAGES_OPTION]
      : modalParams.guidelineProductIds &&
        [...packageOptions, ALL_PACKAGES_OPTION].filter((o) =>
          modalParams.guidelineProductIds.includes(o.value)
        );

  const selectedAddons =
    addons?.filter((addon) => modalParams.addonIds?.includes(addon.value)) ??
    [];

  const createGuidelineNotValid =
    modalParams.userType == null ||
    modalParams.guidelineName == null ||
    modalParams.shootType == null ||
    modalParams.serviceType == null ||
    modalParams.guidelineProductIds == null ||
    modalParams.guidelineProductIds.length === 0 ||
    (modalParams.pdfUrl == null &&
      (modalParams.videoId == null || modalParams.videoId === ''));

  const loading =
    getGuidelineByIdAsAdminQuery.loading ||
    commitCreatePartnerGuidelineAsAdminMutation.loading ||
    commitPartnerGuidelineUpdateAsAdminMutation.loading;

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-header">
          <h4 className="mb-0">
            {modalParams.modalType === 'edit'
              ? 'Edit guideline'
              : 'Create guideline'}
          </h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ width: '1000px', minHeight: '600px' }}
          onSubmit={onSubmit}
        >
          <div className="flex-1" style={{ position: 'relative' }}>
            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Service type</small>
                </label>

                <select
                  className="form-control d-inline"
                  value={modalParams.serviceType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          serviceType: value,
                        },
                      })
                    );
                  }}
                >
                  <option value="">Select service type</option>
                  {serviceTypeSelectOptions.map((serviceType) => (
                    <option key={serviceType.value} value={serviceType.value}>
                      {serviceType.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {modalParams.serviceType !== serviceType.videography && (
              <div className="row">
                <div className="form-group col">
                  <div className="form-check">
                    <input
                      id="isReferralGuideline"
                      className="form-check-input"
                      type="checkbox"
                      disabled={loading || modalParams.guidelineId != null}
                      checked={modalParams.isReferral ?? false}
                      onChange={({ target: { checked } }) =>
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              isReferral: checked,
                            },
                          })
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isReferralGuideline"
                    >
                      This guideline is for referral campaigns
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Guideline name</small>
                </label>

                <input
                  type="text"
                  className="form-control"
                  disabled={loading}
                  value={modalParams.guidelineName ?? ''}
                  onChange={({ target: { value } }) =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          guidelineName: value,
                        },
                      })
                    )
                  }
                />
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">Shoot type</small>
                </label>

                <select
                  className="form-control d-inline"
                  value={modalParams.shootType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          shootType: value,
                          guidelineProductIds:
                            value === 'realestate'
                              ? [ALL_PACKAGES_OPTION.value]
                              : [],
                        },
                      })
                    );
                  }}
                >
                  <option value="">Select shoot type</option>
                  {(shootTypes ?? []).map((shootType) => (
                    <option key={shootType.category} value={shootType.category}>
                      {shootType.displayName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">Packages</small>
                </label>

                <Select
                  isMulti
                  isDisabled={
                    packages == null ||
                    packages.length === 0 ||
                    modalParams.shootType === 'realestate' ||
                    modalParams.userType === USER_TYPE.editor.value ||
                    modalParams.userType === USER_TYPE.editor_vendor.value ||
                    modalParams.userType ===
                      USER_TYPE.referral_customer.value ||
                    loading
                  }
                  value={selectedProductIdsOptions}
                  placeholder="Select"
                  onChange={(selectedOptions) => {
                    const selected = (selectedOptions ?? []).map(
                      (s) => s.value
                    );

                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          guidelineProductIds:
                            selected.length > 0
                              ? selected.includes(ALL_PACKAGES_OPTION.value)
                                ? [ALL_PACKAGES_OPTION.value]
                                : selected
                              : undefined,
                        },
                      })
                    );
                  }}
                  options={[ALL_PACKAGES_OPTION, ...packageOptions]}
                />
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">Guideline user type</small>
                </label>

                <select
                  className="form-control d-inline"
                  value={modalParams.userType}
                  disabled={loading}
                  onChange={({ target: { value } }) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          userType: value,
                          guidelineProductIds:
                            value === USER_TYPE.editor.value ||
                            value === USER_TYPE.referral_customer.value ||
                            value === USER_TYPE.editor_vendor.value
                              ? [ALL_PACKAGES_OPTION.value]
                              : [],
                        },
                      })
                    );
                  }}
                >
                  <option value="">Select user type</option>
                  <option value={USER_TYPE.end_customer.value}>
                    End customer
                  </option>
                  <option value={USER_TYPE.editor.value}>Editor</option>
                  <option value={USER_TYPE.referral_customer.value}>
                    Referral costumer
                  </option>
                  <option value={USER_TYPE.editor_vendor.value}>
                    Editor vendor
                  </option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-6">
                <label>
                  <small className="text-secondary">Pre-selected addons</small>
                </label>

                <Select
                  options={addons}
                  value={selectedAddons}
                  isDisabled={
                    loading ||
                    addons.length === 0 ||
                    modalParams.serviceType == null ||
                    modalParams.shootType == null ||
                    (modalParams.guidelineProductIds ?? []).length === 0
                  }
                  isMulti
                  onChange={(selectedOptions) => {
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          addonIds: selectedOptions?.map((o) => o.value),
                        },
                      })
                    );
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">
                    Google Docs link (for reference only, optional)
                  </small>
                </label>

                <input
                  type="text"
                  className="form-control"
                  value={modalParams.sourceFile}
                  disabled={loading}
                  onChange={({ target: { value } }) =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          sourceFile: value,
                        },
                      })
                    )
                  }
                />
              </div>

              <div className="form-group col">
                <label>
                  <small className="text-secondary">Vimeo ID (optional)</small>
                </label>

                <input
                  type="text"
                  className="form-control"
                  value={modalParams.videoId}
                  disabled={loading}
                  onChange={({ target: { value } }) =>
                    history.replace(
                      locationForModal({
                        location,
                        modal: {
                          ...modalParams,
                          videoId: value,
                        },
                      })
                    )
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label>
                  <small className="text-secondary">PDF:</small>
                </label>

                {modalParams.pdfFileName != null &&
                modalParams.pdfUrl != null ? (
                  <div
                    className="form-control"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      backgroundColor: '#F6F9FF',
                      border: '1px solid #C1C8D4',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="d-flex text-center">
                      <img
                        src={pdfIcon}
                        alt="pdf"
                        style={{
                          width: 35,
                        }}
                      />

                      <div className="d-flex" style={{ width: 250 }}>
                        <span
                          style={{
                            textOverflow: 'ellipsis',
                            display: 'inline-block',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            paddingRight: '0.5em',
                          }}
                        >
                          {modalParams.pdfFileName}
                        </span>
                      </div>
                    </div>

                    <button
                      style={{
                        backgroundColor: 'transparent',
                        borderWidth: 0,
                        paddingTop: 4,
                      }}
                      onClick={(event) => {
                        setUploadProgress(undefined);
                        history.replace(
                          locationForModal({
                            location,
                            modal: {
                              ...modalParams,
                              pdfUrl: undefined,
                              pdfFileName: undefined,
                            },
                          })
                        );
                      }}
                      className="d-flex text-center align-center"
                    >
                      <i
                        className="fa fa-times text-muted fa-lg"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                ) : (
                  <Dropzone
                    accept="application/pdf"
                    multiple={false}
                    disabled={loading || promiserDropZone.isPending}
                    onDrop={(_file) => {
                      const file = _file?.[0];

                      if (file == null) return;

                      const axiosCancelSource = axios.CancelToken.source();

                      promiserDropZone.setPromise(
                        apiFetch(`/api/v2/media/presigned-post-public-asset`, {
                          token: session.token,
                          method: 'POST',
                          body: JSON.stringify({
                            fileType: file.type,
                            fileName: file.name,
                          }),
                        }).then(({ signedUrl, finalUrl }) =>
                          axios
                            .put(signedUrl, file, {
                              onUploadProgress: (progressEvent) => {
                                setUploadProgress(
                                  Math.round(
                                    (progressEvent.loaded * 100) /
                                      progressEvent.total
                                  )
                                );
                              },
                              cancelToken: axiosCancelSource.token,
                              headers: { 'Content-Type': file.type },
                            })
                            .then(() => {
                              history.replace(
                                locationForModal({
                                  location,
                                  modal: {
                                    ...modalParams,
                                    pdfUrl: finalUrl,
                                    pdfFileName: file.name,
                                  },
                                })
                              );
                            })
                        )
                      );
                    }}
                  >
                    <div className="card-body text-center">
                      {uploadProgress == null ? (
                        <p>
                          Drag and drop your PDF here, <br />
                          <span style={{ textDecoration: 'underline' }}>
                            or browse
                          </span>
                        </p>
                      ) : (
                        <small className="text-secondary">
                          Progress: {uploadProgress}%
                        </small>
                      )}
                    </div>
                  </Dropzone>
                )}
              </div>
              <div className="form-group col"></div>
            </div>
          </div>

          <div className="p-2" />

          <div className="d-flex justify-content-between mt-3">
            <button
              type="submit"
              className="btn btn-block btn-dark"
              disabled={createGuidelineNotValid || loading === true}
            >
              {modalParams.modalType === 'edit' ? 'Edit' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export const DuplicateOrDeactivateGuidelineModal = ({
  onDismiss,
  onContinue,
  modalParams,
}: EnterpriseGuidelineProps) => {
  return (
    <Modal onDismiss={onDismiss}>
      <div className="card my-4">
        <div className="card-body">
          <h4 className="mb-2">
            {modalParams.modalType === 'edit'
              ? 'Duplicate guideline'
              : 'Deactivate guideline'}
          </h4>
          <p>
            Are you sure you want to{' '}
            {modalParams.modalType === 'edit' ? 'duplicate' : 'deactivate '}{' '}
            this guideline?
          </p>
        </div>

        <div className="d-flex justify-content-between m-3">
          <button onClick={onContinue} className="btn btn-block btn-dark">
            {modalParams.modalType === 'edit' ? 'Duplicate' : 'Deactivate'}
          </button>
        </div>
      </div>
    </Modal>
  );
};
