import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  gql,
  useMutation,
  useQuery,
} from '../../../../components/ApolloClient';
import { Modal } from '../../../../components/Modal';
import {
  locationForModal,
  ModalRoute,
} from '../../../../components/ModalRoute';
import {
  checkIfCanAccess,
  convertToCents,
  formatMoneyCents,
} from '../../../../utils';
import { isPartnerBooking } from '../selectors';
import { PackageDetails } from './PackageDetails';
import { TransactionDetails } from './TransactionDetails';
import { multiline } from '../../../../utils/strings';
import { Tooltip } from '../../../../components/Tooltip';
import { PaymentMethodSelector } from './PaymentMethodSelector';
import { EnterpriseTransactionDetails } from './EnterpriseTransactionDetails';

import { ENABLE_CUSTOMER_EXTRA_CHARGES } from '../../../../config';
import { CreditCardInputs, CreditCardLoader } from './CreditCardLoader';
import { usePromise } from '../../../../components/usePromise';

enum TabCode {
  PACKAGE = 'PACKAGE',
  TRANSACTIONS = 'TRANSACTIONS',
  EXTRA_CHARGES = 'EXTRA_CHARGES',
  RELATED_GIFT_CARDS = 'RELATED_GIFT_CARDS',
  COUPON = 'COUPON',
}

const MODAL_NAME_BOOKING_EXTRA_CHARGE = 'booking-extra-charge';

function prettifyConstant(val: string) {
  const spaced = val.split('_').join(' ');

  return spaced[0].toUpperCase() + spaced.slice(1);
}

function defaultPgPayRatio({ reason }: { reason?: string }) {
  return reason === 'other' ? 0 : 1;
}

function parseAmount(valString?: string) {
  const val = parseFloat(valString ?? '');

  return Number.isNaN(val) ? undefined : val;
}

function BookingExtraChargeModal({
  onDismiss,
  onReload,
  booking,
  session,
}: {
  onDismiss: () => void;
  onReload: () => Promise<void>;
  booking: { uid?: string; customer_uid?: string; country?: string };
  session: { token: string };
}) {
  const [paymentMethodToken, setPaymentMethodToken] = useState<
    string | undefined
  >(undefined);

  const [payWithNewCreditCard, setPayWithNewCreditCard] = useState<boolean>(
    false
  );

  const [chargeDifferentAmount, setChargeDifferentAmount] = useState<boolean>(
    false
  );

  const [
    processingNewCreditCard,
    setProcessingNewCreditCard,
  ] = useState<boolean>(false);

  const { setPromise: setProcessingPayment } = usePromise();

  const [state, setState] = React.useState<{
    reason?: string;
    notes?: string;
    publicNotes?: string;
    shouldPgGetPaid: boolean;

    amountString?: string;
    providerPayString?: string;
    productId?: string | null;
  }>({
    shouldPgGetPaid: false,
    productId: null,
  });

  const [validate, validateMutation] = useMutation<
    {
      bookingExtraChargeCreateValidate: {
        reasons: string[];

        token: string;
        amount?: number;
        providerPay?: number;
      };
    },
    {
      bookingId: string;
      amount?: number;
      providerPay?: number;
      productId?: string;
    }
  >(gql`
    mutation BookingExtraChargesCreateValidation(
      $bookingId: ID!
      $amount: Int
      $providerPay: Int
      $productId: ID
    ) {
      bookingExtraChargeCreateValidate(
        input: {
          bookingId: $bookingId
          amount: $amount
          providerPay: $providerPay
          productId: $productId
        }
      ) {
        reasons
        token
        amount
        providerPay
      }
    }
  `);

  const [commit, commitMutation] = useMutation<
    {
      bookingExtraChargeCreateCommit: {
        extraCharge: { id: string };
      };
    },
    {
      bookingId: string;
      token: string;
      reason?: string;
      notes?: string;
      publicNotes?: string;
      paymentMethodToken?: string;
      amount?: number;
      providerPay?: number;
      productId?: string;
    }
  >(gql`
    mutation BookingExtraChargesCreateCommit(
      $bookingId: ID!
      $amount: Int
      $providerPay: Int
      $token: String!
      $reason: String
      $notes: String
      $publicNotes: String
      $paymentMethodToken: String
      $productId: ID
    ) {
      bookingExtraChargeCreateCommit(
        input: {
          bookingId: $bookingId
          amount: $amount
          providerPay: $providerPay
          token: $token
          reason: $reason
          notes: $notes
          publicNotes: $publicNotes
          paymentMethodToken: $paymentMethodToken
          productId: $productId
        }
      ) {
        extraCharge {
          id
        }
      }
    }
  `);

  const bookingId = booking.uid;

  const productFeesQuery = useQuery<{
    productFeesListByBookingIdAsAdmin?: {
      id: string;
      displayName?: string;
      price: string;
      providerPay: string;
    }[];
  }>(
    gql`
      query PackageTabExtraChargesProductFees($bookingId: ID!) {
        productFeesListByBookingIdAsAdmin(bookingId: $bookingId) {
          displayName
          id
          price
          providerPay
        }
      }
    `,
    {
      variables: { bookingId: booking.uid },
    }
  );

  const productFees = productFeesQuery.data?.productFeesListByBookingIdAsAdmin;

  React.useEffect(() => {
    if (bookingId == null) return;

    validate({
      variables: {
        bookingId,
        ...(state.amountString != null && {
          amount: convertToCents(parseAmount(state.amountString)),
        }),
        ...(state.shouldPgGetPaid != null &&
          state.providerPayString != null && {
            providerPay:
              state.shouldPgGetPaid !== true
                ? 0
                : convertToCents(parseAmount(state.providerPayString)),
          }),
        ...(state.productId != null &&
          state.productId !== 'OTHER' && { productId: state.productId }),
      },
    });
  }, [
    bookingId,
    state.amountString,
    state.providerPayString,
    state.shouldPgGetPaid,
    state.productId,
    paymentMethodToken,
    validate,
  ]);

  const onCommitExtraCharge = () => {
    const token = validateMutation.data?.bookingExtraChargeCreateValidate.token;
    const amount =
      state.amountString != null
        ? convertToCents(parseAmount(state.amountString))
        : null;

    const providerPay =
      state.providerPayString != null && state.shouldPgGetPaid != null
        ? state.shouldPgGetPaid !== true
          ? 0
          : convertToCents(parseAmount(state.providerPayString))
        : null;

    if (
      bookingId == null ||
      token == null ||
      (state.productId == null &&
        (amount == null || providerPay == null || state.reason == null))
    ) {
      return;
    }

    commit({
      variables: {
        bookingId,

        ...(amount != null && { amount: amount }),
        ...(providerPay != null && { providerPay }),
        ...(state.productId != null &&
          state.productId !== 'OTHER' && {
            productId: state.productId,
          }),
        notes: state.notes,
        ...(state.reason && { reason: state.reason }),
        ...(state.publicNotes != null &&
          state.publicNotes !== '' && { publicNotes: state.publicNotes }),
        token,
        paymentMethodToken,
      },
    })
      .then(onReload)
      .then(onDismiss);
  };

  return (
    <Modal onDismiss={onDismiss}>
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Extra charge</h4>
        </div>

        <form
          className="card-body d-flex flex-column"
          style={{ minWidth: '1000px', minHeight: '600px' }}
          onSubmit={(ev) => {
            ev.preventDefault();

            if (
              validateMutation.loading ||
              commitMutation.loading ||
              processingNewCreditCard ||
              (isPartnerBooking({ booking }) !== true &&
                booking.customer_uid != null &&
                paymentMethodToken == null) ||
              (chargeDifferentAmount === true && state.amountString == null) ||
              (chargeDifferentAmount === false && state.productId == null) ||
              (state.shouldPgGetPaid === true && state.publicNotes == null)
            ) {
              return;
            }

            if (
              window.confirm(
                `Are you sure you want to charge ${formatMoneyCents(
                  validateMutation.data?.bookingExtraChargeCreateValidate
                    .amount,
                  null
                )} to the customer and paying out ${formatMoneyCents(
                  validateMutation.data?.bookingExtraChargeCreateValidate
                    .providerPay,
                  null
                )} to the provider?`
              )
            ) {
              return onCommitExtraCharge();
            }
          }}
        >
          {commitMutation.loading === false && commitMutation.error != null && (
            <div className="alert alert-danger" role="alert">
              {commitMutation.error?.graphQLErrors?.[0]?.message ??
                'Unknown error'}
            </div>
          )}
          <div className="card">
            <div className="card-body">
              <h5>Information required</h5>

              <div className="pt-2" />

              <div>
                <span>Charge $</span>

                <select
                  className="form-control d-inline w-50 ml-2"
                  value={state.productId ?? ''}
                  onChange={({ target: { value } }) => {
                    if (value === 'OTHER') {
                      setChargeDifferentAmount(true);
                      setState({
                        ...state,
                        productId: 'OTHER',
                      });
                    } else {
                      setChargeDifferentAmount(false);
                      setState({
                        ...state,
                        productId: value === '' ? undefined : value,
                      });
                    }
                  }}
                >
                  <option value="">Select a product fee</option>

                  {(productFees ?? []).map((fee) => (
                    <option key={fee.id} value={fee.id}>
                      {fee.displayName ?? ''} -{' '}
                      {formatMoneyCents(parseAmount(fee.price), null)}
                    </option>
                  ))}

                  <option value="OTHER">Other</option>
                </select>
                <div className="pt-2" />

                <div>
                  {chargeDifferentAmount === true && (
                    <>
                      <div className="pt-2" />
                      <div>
                        <span>Charge reason</span>

                        <select
                          className="form-control d-inline w-50 ml-2"
                          value={state.reason ?? ''}
                          onChange={({ target: { value } }) =>
                            setState((state) => ({
                              ...state,
                              reason: value === '' ? undefined : value,
                            }))
                          }
                        >
                          <option value="">Select a reason</option>

                          {validateMutation.data?.bookingExtraChargeCreateValidate.reasons.map(
                            (availableReason) => (
                              <option
                                key={availableReason}
                                value={availableReason}
                              >
                                {prettifyConstant(availableReason)}
                                {availableReason === 'other'
                                  ? ' (Please specify)'
                                  : null}
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className="pt-2" />

                      <div>
                        <span>Charge amount $</span>

                        <input
                          type="number"
                          className="form-control d-inline-block w-50 ml-2"
                          placeholder="Amount"
                          onFocus={(ev) => ev.target.select()}
                          value={state.amountString ?? ''}
                          onChange={({ target: { value } }) =>
                            setState((state) => ({
                              ...state,
                              amountString: value === '' ? undefined : value,
                            }))
                          }
                          onBlur={() =>
                            setState((state) => {
                              const amountParsed = parseAmount(
                                state.amountString
                              );
                              if (
                                state.providerPayString != null ||
                                amountParsed == null
                              ) {
                                return state;
                              }

                              return {
                                ...state,
                                providerPayString: `${
                                  defaultPgPayRatio({
                                    reason: state.reason,
                                  }) * amountParsed
                                }`,
                              };
                            })
                          }
                        />
                      </div>

                      <div className="pt-2" />

                      <div>
                        <span>
                          Should the photographer get paid for this charge?
                        </span>

                        <div className="form-check form-check-inline ml-2">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="deductFromPhotographerOptions"
                              checked={state.shouldPgGetPaid === true}
                              onChange={({ target: { value } }) =>
                                setState((state) => ({
                                  ...state,
                                  shouldPgGetPaid: true,
                                }))
                              }
                            />
                            Yes
                          </label>
                        </div>

                        <div className="form-check form-check-inline ml-2">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="deductFromPhotographerOptions"
                              checked={state.shouldPgGetPaid !== true}
                              onChange={({ target: { value } }) =>
                                setState((state) => ({
                                  ...state,
                                  shouldPgGetPaid: false,
                                }))
                              }
                            />
                            No
                          </label>
                        </div>
                      </div>

                      {state.shouldPgGetPaid === true && (
                        <>
                          <div className="pt-2" />

                          <div>
                            <span>
                              How much should the photographer get paid? $
                            </span>

                            <input
                              type="number"
                              className="form-control d-inline-block w-50"
                              required={state.shouldPgGetPaid}
                              onFocus={(ev) => ev.target.select()}
                              value={state.providerPayString ?? ''}
                              onChange={({ target: { value } }) =>
                                setState((state) => ({
                                  ...state,
                                  providerPayString:
                                    value === '' ? undefined : value,
                                }))
                              }
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="pt-2" />

              <div>
                <span>
                  Internal notes (for audit purposes only - hidden to the
                  provider):
                </span>

                <input
                  type="text"
                  className="form-control d-inline w-50 ml-2"
                  value={state.notes ?? ''}
                  onChange={({ target: { value } }) =>
                    setState((state) => ({
                      ...state,
                      notes: value === '' ? undefined : value,
                    }))
                  }
                />
              </div>
              {state.shouldPgGetPaid === true ? (
                <>
                  <div className="pt-2" />

                  <div>
                    <span>Public notes (shown to the provider):</span>

                    <input
                      type="text"
                      className="form-control d-inline w-50 ml-2"
                      value={state.publicNotes ?? ''}
                      onChange={({ target: { value } }) =>
                        setState((state) => ({
                          ...state,
                          publicNotes: value === '' ? undefined : value,
                        }))
                      }
                    />
                  </div>
                </>
              ) : null}

              <div className="pt-2" />

              {isPartnerBooking({ booking }) !== true &&
                booking.customer_uid &&
                (payWithNewCreditCard === false ? (
                  <div className="flex-1 d-flex flex-column mt-2 mb-2">
                    <div className="flex-1">
                      <span>Payment method: </span>

                      <div className="d-flex mt-2 mb-2" />

                      <div className="w-50 d-inline">
                        <PaymentMethodSelector
                          bookingClone={booking}
                          session={session}
                          selectedPaymentMethodToken={paymentMethodToken ?? ''}
                          onPaymentMethodTokenChange={({
                            paymentMethodToken,
                          }) => setPaymentMethodToken(paymentMethodToken)}
                        />

                        <button
                          className="btn btn-link py-2 px-0 mt-3 mb-5 font-weight-normal"
                          onClick={() => {
                            setPayWithNewCreditCard(true);
                            setPaymentMethodToken(undefined);
                          }}
                        >
                          Pay with another credit card
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <CreditCardLoader
                    bookingId={booking.uid}
                    booking={booking}
                    session={session}
                    onProcessing={(processingPayment) =>
                      setProcessingNewCreditCard(processingPayment)
                    }
                  >
                    {({
                      tokenize,
                      cardType,
                      cvvValid,
                      expirationDateValid,
                      formValid,
                      loading,
                      numberValid,
                    }) => (
                      <div className="flex-1 d-flex flex-column mt-2 mb-2">
                        <div
                          className="flex-1 flex-column "
                          style={{ position: 'relative' }}
                        >
                          <span>Payment method: </span>

                          <div className="d-flex mt-2 mb-2" />

                          <CreditCardInputs
                            numberValid={numberValid}
                            expirationDateValid={expirationDateValid}
                            cvvValid={cvvValid}
                            cardType={cardType}
                            country={booking.country}
                          />

                          <button
                            type="button"
                            className="btn btn-link px-0 pt-0"
                            onClick={() => setPayWithNewCreditCard(false)}
                            disabled={processingNewCreditCard}
                          >
                            Show saved payment methods
                          </button>

                          {formValid === true && loading === false && (
                            <div className="flex-1 d-flex">
                              <button
                                type="button"
                                className="btn btn-primary"
                                disabled={processingNewCreditCard}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setProcessingPayment(
                                    tokenize().then(
                                      ({ paymentMethodToken }) => {
                                        setProcessingNewCreditCard(false);
                                        setPaymentMethodToken(
                                          paymentMethodToken
                                        );
                                        setPayWithNewCreditCard(false);
                                      }
                                    )
                                  );
                                }}
                              >
                                Save payment method
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </CreditCardLoader>
                ))}
            </div>
          </div>

          <div className="flex-grow-1" />

          <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-secondary mr-2" onClick={onDismiss}>
              Close
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                validateMutation.loading ||
                commitMutation.loading ||
                processingNewCreditCard ||
                (isPartnerBooking({ booking }) !== true &&
                  booking.customer_uid != null &&
                  paymentMethodToken == null) ||
                (chargeDifferentAmount === true &&
                  state.amountString == null) ||
                (chargeDifferentAmount === false && state.productId == null) ||
                (state.shouldPgGetPaid === true && state.publicNotes == null)
              }
            >
              Charge
              {validateMutation.data?.bookingExtraChargeCreateValidate.amount !=
                null && (
                <>
                  {' '}
                  {formatMoneyCents(
                    validateMutation.data?.bookingExtraChargeCreateValidate
                      .amount,
                    null
                  )}
                </>
              )}
              {validateMutation.data?.bookingExtraChargeCreateValidate
                .providerPay != null && (
                <>
                  {' '}
                  pay photographer{' '}
                  {formatMoneyCents(
                    validateMutation.data?.bookingExtraChargeCreateValidate
                      .providerPay,
                    null
                  )}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

function ExtraChargesDetails({
  hide,
  booking,
  session,
}: {
  hide: boolean;
  booking: { uid: string; centralize_editing_enabled?: boolean };
  session: { token: string };
}) {
  const location = useLocation();

  const extraChargesQuery = useQuery<{
    bookingById?: {
      id: string;
      extraCharges?: {
        edges?: {
          id: string;
          amount?: number;
          providerPay?: number;
          createdAt: string;
          reason?: string;
          notes?: string;
          publicNotes?: string;
          providerPayCentralEditing?: number;
        }[];
      };
    };
  }>(
    gql`
      query PackageTabExtraCharges($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id
          extraCharges {
            edges {
              id
              amount
              providerPay
              providerPayCentralEditing
              createdAt

              reason
              notes
              publicNotes
            }
          }
        }
      }
    `,
    { skip: booking.uid == null, variables: { bookingId: booking.uid } }
  );

  return (
    <div
      className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}
      style={{ minHeight: '350px' }}
    >
      <div className="d-flex justify-content-end">
        <Link
          className="btn btn-secondary"
          to={locationForModal({
            location,
            modal: { modalName: MODAL_NAME_BOOKING_EXTRA_CHARGE },
          })}
        >
          New Charge
        </Link>
      </div>

      <div className="p-2" />

      <div className="table-responsive">
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="text-muted text-truncate">Reason</th>
              <th className="text-muted text-truncate">Amount</th>
              <th className="text-muted text-truncate">Provider Pay</th>
              <th className="text-muted text-truncate">Creation Date</th>
              <th className="text-muted text-truncate">Id</th>
            </tr>
          </thead>

          <tbody>
            {extraChargesQuery.data?.bookingById?.extraCharges?.edges?.map(
              (extraCharge) => (
                <tr key={extraCharge.id} style={{ whiteSpace: 'nowrap' }}>
                  <td>
                    {extraCharge.reason == null
                      ? null
                      : prettifyConstant(extraCharge.reason)}

                    {extraCharge.notes == null ? null : (
                      <Tooltip label={multiline(extraCharge.notes)}>
                        <i className="fa fa-sticky-note text-primary ml-2" />
                      </Tooltip>
                    )}
                    {extraCharge.publicNotes == null ? null : (
                      <Tooltip label={multiline(extraCharge.publicNotes)}>
                        <i className="fa fa-sticky-note text-danger ml-2" />
                      </Tooltip>
                    )}
                  </td>

                  <td>
                    {extraCharge.amount == null
                      ? null
                      : formatMoneyCents(extraCharge.amount, null)}
                  </td>

                  <td>
                    {booking.centralize_editing_enabled === true
                      ? formatMoneyCents(
                          extraCharge.providerPayCentralEditing ??
                            extraCharge.providerPay,
                          null
                        )
                      : formatMoneyCents(extraCharge.providerPay, null)}
                  </td>

                  <td>
                    {moment(extraCharge.createdAt).format(
                      'ddd, MMM Do YYYY, h:mm:ss a zz'
                    )}
                  </td>

                  <td>
                    <small>{extraCharge.id}</small>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      <ModalRoute
        modalName={MODAL_NAME_BOOKING_EXTRA_CHARGE}
        render={({ history, location, modalParams }) => (
          <BookingExtraChargeModal
            booking={booking}
            session={session}
            onReload={async () => {
              await extraChargesQuery.refetch();
            }}
            onDismiss={() =>
              history.push(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />
    </div>
  );
}

type IGiftCard = {
  id: string;
  remaining?: number;
  region?: { uid: string; currency?: string };
};

function GiftCardRow({ giftCard }: { giftCard: IGiftCard }) {
  const [showCode, showCodeMutation] = useMutation<
    { giftCardRequestCodeAsAdmin?: string },
    { giftCardId: string }
  >(
    gql`
      mutation RequestGiftCardCodeInPackages($giftCardId: ID!) {
        giftCardRequestCodeAsAdmin(giftCardId: $giftCardId)
      }
    `
  );

  return (
    <tr key={giftCard.id} style={{ whiteSpace: 'nowrap' }}>
      <td>
        <div className="input-group">
          <input
            className="form-control"
            style={{ flex: 1 }}
            placeholder="******"
            readOnly
            value={showCodeMutation.data?.giftCardRequestCodeAsAdmin ?? ''}
          />
          {!showCodeMutation.data && (
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                onClick={() =>
                  showCode({ variables: { giftCardId: giftCard.id } })
                }
              >
                <i className="mr-2 fa fa-eye" />
                Show
              </button>
            </div>
          )}
        </div>
      </td>

      <td>
        {giftCard.remaining != null &&
          giftCard.region?.currency != null &&
          formatMoneyCents(giftCard.remaining, {
            currency: giftCard.region.currency,
          })}
      </td>

      <td>
        <small>{giftCard.id}</small>
      </td>
    </tr>
  );
}

function RelatedGiftCardsDetails({
  hide,
  booking,
  session,
}: {
  hide: boolean;
  booking: { uid: string };
  session: { token: string };
}) {
  const giftCardsQuery = useQuery<{
    bookingById?: {
      id: string;
      giftCardsDueToCancellation?: {
        edges?: IGiftCard[];
      };
    };
  }>(
    gql`
      query PackageTabGiftCards($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id

          giftCardsDueToCancellation {
            edges {
              id
              remaining
              region {
                uid
                currency
              }
            }
          }
        }
      }
    `,
    { skip: booking.uid == null, variables: { bookingId: booking.uid } }
  );

  return (
    <div
      className={'card-body flex-column' + (hide ? ' d-none' : ' d-flex')}
      style={{ minHeight: '350px' }}
    >
      <div className="table-responsive">
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th className="text-muted text-truncate">Code</th>
              <th className="text-muted text-truncate">Remaining</th>
              <th className="text-muted text-truncate">Id</th>
            </tr>
          </thead>

          <tbody>
            {giftCardsQuery.data?.bookingById?.giftCardsDueToCancellation?.edges?.map(
              (giftCard) => (
                <GiftCardRow key={giftCard.id} giftCard={giftCard} />
              )
            )}
          </tbody>
        </table>
      </div>

      <ModalRoute
        modalName={MODAL_NAME_BOOKING_EXTRA_CHARGE}
        render={({ history, location, modalParams }) => (
          <BookingExtraChargeModal
            booking={booking}
            session={session}
            onReload={async () => {
              await giftCardsQuery.refetch();
            }}
            onDismiss={() =>
              history.push(
                locationForModal({
                  location,
                  modal: undefined,
                })
              )
            }
          />
        )}
      />
    </div>
  );
}

function RelatedCouponDetails({ booking }: { booking: { uid: string } }) {
  const couponQuery = useQuery<{
    bookingById?: {
      id: string;
      region?: {
        uid: string;
        currency: string;
      };
      package?: {
        uid: string;
        price: number;
      };
      coupon?: {
        id: string;
        code?: string;
        type?: string;
        value?: number;
      };
    };
  }>(
    gql`
      query PackageTabGiftCardsRelatedCouponDetails($bookingId: ID!) {
        bookingById(id: $bookingId) {
          id

          region {
            uid
            currency
          }

          package {
            uid
            price
          }

          coupon {
            id
            code
            type
            value
          }
        }
      }
    `,
    {
      skip: booking.uid == null,
      variables: { bookingId: booking.uid },
    }
  );

  const region = couponQuery.data?.bookingById?.region;
  const bookingPackage = couponQuery.data?.bookingById?.package;
  const coupon = couponQuery.data?.bookingById?.coupon;

  const couponValue =
    coupon == null
      ? null
      : coupon.type === 'percent'
      ? `${coupon.value}% (${formatMoneyCents(
          (bookingPackage?.price ?? 0) * ((coupon.value ?? 0) / 100) * -1,
          {
            currency: region?.currency,
          }
        )})`
      : formatMoneyCents(coupon.value, {
          currency: region?.currency,
        });

  return (
    <div
      className="card-body flex-column d-flex"
      style={{ minHeight: '350px' }}
    >
      {couponQuery.loading && couponQuery.data == null ? (
        <div className="tw-h-full tw-flex tw-justify-center tw-items-center">
          <p className="tw-bold">Loading...</p>
        </div>
      ) : coupon == null ? (
        <div className="tw-h-full tw-flex tw-justify-center tw-items-center">
          <p className="tw-bold">No coupon found for this booking</p>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th className="text-muted text-truncate">Code</th>
                <th className="text-muted text-truncate">Type</th>
                <th className="text-muted text-truncate">Value</th>
              </tr>
            </thead>

            <tbody>
              <tr style={{ whiteSpace: 'nowrap' }}>
                <td>{coupon.code}</td>
                <td className="tw-capitalize">{coupon.type}</td>
                <td>{couponValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export function PackageTab({
  booking,
  bookingClone,
  session,
  location,
  bookingChangedKeys,
  onServiceTypeChange,
  onPackageChange,
  history,
  onDetailsChange,
  onPropertySizeChange,
}) {
  const [selectedTab, setSelectedTab] = useState<TabCode>(TabCode.PACKAGE);

  return (
    <div className="col d-flex flex-column" style={{ minWidth: 0 }}>
      <div className="card flex-1">
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs">
            <li className="nav-item">
              <button
                className={
                  'btn btn-link nav-link' +
                  (selectedTab !== TabCode.PACKAGE ? '' : ' active')
                }
                style={{ padding: '8px 12px' }}
                onClick={() => setSelectedTab(TabCode.PACKAGE)}
              >
                <strong className="mb-0">Package</strong>
              </button>
            </li>

            <li className="nav-item">
              <button
                className={
                  'btn btn-link nav-link' +
                  (selectedTab !== TabCode.TRANSACTIONS ? '' : ' active')
                }
                style={{ padding: '8px 12px' }}
                onClick={() => setSelectedTab(TabCode.TRANSACTIONS)}
              >
                <strong className="mb-0">Transactions</strong>
              </button>
            </li>

            {(isPartnerBooking({ booking }) === true ||
              ENABLE_CUSTOMER_EXTRA_CHARGES === true) && (
              <li className="nav-item">
                <button
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.EXTRA_CHARGES ? '' : ' active')
                  }
                  style={{ padding: '8px 12px' }}
                  onClick={() => setSelectedTab(TabCode.EXTRA_CHARGES)}
                >
                  <strong className="mb-0">Extra charges</strong>
                </button>
              </li>
            )}

            {isPartnerBooking({ booking }) !== true && (
              <li className="nav-item">
                <button
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.RELATED_GIFT_CARDS
                      ? ''
                      : ' active')
                  }
                  style={{ padding: '8px 12px' }}
                  onClick={() => setSelectedTab(TabCode.RELATED_GIFT_CARDS)}
                >
                  <strong className="mb-0">Gift cards</strong>
                </button>
              </li>
            )}

            {isPartnerBooking({ booking }) !== true && (
              <li className="nav-item">
                <button
                  className={
                    'btn btn-link nav-link' +
                    (selectedTab !== TabCode.COUPON ? '' : ' active')
                  }
                  style={{ padding: '8px 12px' }}
                  onClick={() => setSelectedTab(TabCode.COUPON)}
                >
                  <strong className="mb-0">Coupon</strong>
                </button>
              </li>
            )}
          </ul>
        </div>

        <PackageDetails
          session={session}
          booking={booking}
          bookingClone={bookingClone}
          bookingChangedKeys={bookingChangedKeys}
          onServiceTypeChange={onServiceTypeChange}
          onPackageChange={onPackageChange}
          onDetailsChange={onDetailsChange}
          onPropertySizeChange={onPropertySizeChange}
          hide={selectedTab !== TabCode.PACKAGE}
        />

        {isPartnerBooking({ booking }) === true ? (
          checkIfCanAccess(session, 'enterpriseBookingTransactions') ? (
            <EnterpriseTransactionDetails
              session={session}
              booking={booking}
              location={location}
              hide={selectedTab !== TabCode.TRANSACTIONS}
              history={history}
            />
          ) : (
            <div
              className={`alert alert-danger m-3 ${
                selectedTab !== TabCode.TRANSACTIONS ? 'd-none' : ''
              }`}
            >
              You do not have access to view enterprise transactions.
            </div>
          )
        ) : (
          <TransactionDetails
            session={session}
            booking={booking}
            location={location}
            hide={selectedTab !== TabCode.TRANSACTIONS}
            history={history}
          />
        )}

        <ExtraChargesDetails
          booking={booking}
          session={session}
          hide={selectedTab !== TabCode.EXTRA_CHARGES}
        />

        <RelatedGiftCardsDetails
          booking={booking}
          session={session}
          hide={selectedTab !== TabCode.RELATED_GIFT_CARDS}
        />

        {selectedTab === TabCode.COUPON && (
          <RelatedCouponDetails booking={booking} />
        )}
      </div>
    </div>
  );
}
