import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import BaseLayout from '../../components/BaseLayout';
import { AiEcommerceVideoListRoute } from './list';
import { AiEcommerceVideoDetails } from './details';

export const AiEcommerceVideoRoute = ({
  session,
  match,
  match: { path: basePath },
  history,
  location,
}) => (
  <Switch>
    <Route
      render={() => (
        <BaseLayout session={session} match={match}>
          <Switch>
            <Route
              exact
              path={basePath}
              render={(props) => (
                <AiEcommerceVideoListRoute
                  {...props}
                  location={location}
                  session={session}
                  history={history}
                />
              )}
            />

            <Route
              exact
              path={`${basePath}/:jobId`}
              render={(props) => {
                return <AiEcommerceVideoDetails {...props} session={session} />;
              }}
            />

            <Route
              render={({ location }) => (
                <Redirect to={{ ...location, pathname: basePath }} />
              )}
            />
          </Switch>
        </BaseLayout>
      )}
    />
  </Switch>
);
